export const useSupplierStore = defineStore("supplier", () => {
  const supplierSettings = ref<SupplierSettings>({})
  const isOrganizationSupplier = ref<boolean>(false)

  const authStore = useAuthStore()
  const userStore = useUserStore()
  const organizationStore = useOrganizationStore()
  const settingsStore = useSettingsStore()

  const { isSuperAdmin, isSupplier, currentOrganization } = storeToRefs(authStore)
  const { currentUser } = storeToRefs(userStore)
  const { sourceSupplierModuleEnabled } = storeToRefs(settingsStore)

  const isSupplierMechanic = computed(() => currentUser.value?.userRole === UserRole.SUPPLIER_MECHANIC)
  const isSupplierPlanner = computed(() => currentUser.value?.userRole === UserRole.SUPPLIER_PLANNER)
  const isSupplierManager = computed(() => currentUser.value?.userRole === UserRole.SUPPLIER_MANAGER)

  const applyFilterOnMechanicName = computed(() => {
    // Supplier module is disabled - so this setting can never be true
    if (!sourceSupplierModuleEnabled.value) {
      return false
    }

    // This is only relevant for users that have to mechanic role
    if (!isSupplierMechanic.value) {
      return false
    }

    // This determines if the supplier settings require that data is automatically filtered based on the mechanic's name
    return supplierSettings.value.filterOnMechanicName ?? false
  })

  const isLoggedInOnSupplier = computed<boolean>(() => {
    // Not logged in - so no need to check
    if (!currentUser.value) {
      return false
    }

    // Not a supplier organization or supplier module is disabled
    if (!isOrganizationSupplier.value || !sourceSupplierModuleEnabled.value) {
      return false
    }

    // SuperAdmin can access all organizations
    if (isSuperAdmin.value) {
      return true
    }

    // Check if user is from a supplier organization or is a SuperAdmin
    if (!isSupplier.value) {
      return false
    }

    // Is current user logged in on his own organization
    // Shouldn't be possible, but prevent supplier module in other organizations
    return currentUser.value.organization === currentOrganization.value
  })

  const isSupplierAdministrator = computed(() => {
    // Uses the same logic as isLoggedInOnSupplier, but also needs to check if the user has the Manager role
    if (!isLoggedInOnSupplier.value) {
      return false
    }

    return isSuperAdmin.value || isSupplierManager.value
  })

  watchEffect(async () => {
    if (!currentOrganization.value || !currentUser.value) {
      isOrganizationSupplier.value = false
      return
    }

    isOrganizationSupplier.value = await organizationStore.isOrganizationOfType(currentOrganization.value, OrganizationType.SUPPLIER)

    const organizationSettings = await useOrganizationStore().getSettings(currentUser.value.organization)
    if (organizationSettings.supplierSettings) {
      supplierSettings.value = organizationSettings.supplierSettings
    }
  })

  return {
    /** This only returns true if:
     * - Supplier has activated the supplier module
     * - Supplier enabled the setting: Filter on mechanic name
     * - User is a supplier user with the Mechanic role
     */
    applyFilterOnMechanicName,
    /** If the user is from a supplier and has the Mechanic role */
    isSupplierMechanic,
    /** If the user is from a supplier and has the Planner role */
    isSupplierPlanner,
    /** If the user is from a supplier and has the Manager role */
    isSupplierManager,
    /** This only returns true if current organization is a supplier organization and:
     * - Supplier has activated the supplier module
     * - User is logged in on his own organization OR User is SuperAdmin
     */
    isLoggedInOnSupplier,
    /** This only returns true if current organization is a supplier organization and:
     * - Supplier has activated the supplier module
     * - User is logged in on his own organization and has the Manager role OR User is SuperAdmin
     */
    isSupplierAdministrator,
  }
})
